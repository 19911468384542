import React from "react";
import { Stack, Text, Box, Flex, Image } from "@chakra-ui/core";

import { OVERVIEW_HEIGHT } from "./ProjectOverview";
import { HEADER_HEIGHT } from "./Header/Header";

const ProjectIntro = ({
  projectName,
  projectDesc,
  projectImg,
  projectColor,
}) => {
  return (
    <Box width="100%" backgroundColor={projectColor}>
      <Stack
        spacing={{ base: 12, xl: 0 }}
        flexDirection={{ base: "column", xl: "row" }}
        width={{ base: "100%", xl: "1264px" }}
        margin="0 auto"
        paddingX={8}
        paddingY={{ base: 20 }}
        alignItems="center"
        height={{
          xl: `calc(100vh - ${OVERVIEW_HEIGHT}px - ${HEADER_HEIGHT}px)`,
        }}
      >
        <Box flex={{ lg: "0 0 25%" }}>
          <Text
            textAlign="center"
            color="gray.100"
            fontWeight="bold"
            fontSize="72px"
          >
            {projectName}
          </Text>
        </Box>
        <Flex
          flex={{ lg: "0 0 50%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Image src={projectImg} width={{ base: "80%", xl: "100%" }} />
        </Flex>
        <Box flex={{ lg: "0 0 25%" }}>
          <Text
            color="gray.100"
            fontWeight="medium"
            fontSize="42px"
            textAlign={{ base: "center", xl: "left" }}
          >
            {projectDesc}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProjectIntro;
