import React from "react";
import { Text, Box, Flex, Image, FlexProps } from "@chakra-ui/core";

// typography
import BodyText from "../../Typography/BodyText";
import Heading4 from "../../Typography/Heading4";

interface IpnosScenarioProps extends FlexProps {
  scenarioDesc: string;
  scenarioNumber: string;
  scenarioActions: string;
  scenarioOutcome: string;
  scenarioImage: string;
}

const IpnosScenario = ({
  scenarioDesc,
  scenarioNumber,
  scenarioActions,
  scenarioOutcome,
  scenarioImage,
  ...rest
}: IpnosScenarioProps) => {
  return (
    <Box {...rest}>
      <Flex
        backgroundColor="brandDarkGrey"
        p={4}
        mb={10}
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Flex
          borderRadius={4}
          flex="0 0 60px"
          backgroundColor="#131414"
          alignItems="center"
          justifyContent="center"
          mb={{ base: 4, sm: 0 }}
        >
          <Text color="gray.400" fontSize="xl" fontWeight="bold">
            {scenarioNumber}
          </Text>
        </Flex>
        <Box px={4}>
          <BodyText>{scenarioDesc}</BodyText>
        </Box>
      </Flex>
      <Flex flexDirection={{ base: "column-reverse", lg: "row" }}>
        <Flex flexDirection="column" flex="0 0 40%" pr={8} justify="center">
          <Box mb={8}>
            <Heading4 mb={2}>Actions</Heading4>
            <Box>
              <BodyText color="gray.400">{scenarioActions}</BodyText>
            </Box>
          </Box>
          <Box>
            <Heading4 mb={2}>Outcome</Heading4>
            <BodyText color="gray.400">{scenarioOutcome}</BodyText>
          </Box>
        </Flex>
        <Box mb={{ base: 10, lg: 0 }}>
          <Image src={scenarioImage} maxWidth="100%" />
        </Box>
      </Flex>
    </Box>
  );
};

export default IpnosScenario;
