// libraries
import React from "react";
import { Box, Flex, Stack, Text, Image } from "@chakra-ui/core";

// components
import ProjectIntro from "../ProjectIntro";
import ProjectContentContainer from "../ProjectContentContainer";
import ProjectOverview from "../ProjectOverview";
import ProjectSection from "../ProjectSection";
import ProjectSectionTitle from "../ProjectSectionTitle/ProjectSectionTitle";
import TextContainerWithBackground from "../TextContainerWithBackground";
import IpnosScenario from "./Scenario/Scenario";
import IpnosSystemComponent from "./SystemComponent/SystemComponent";
import IpnosPrinciple from "./Principle/Principle";

// typography
import BodyText from "../Typography/BodyText";
import Heading3 from "../Typography/Heading3";
import Heading4 from "../Typography/Heading4";

// images
import IpnosProjectHero from "../../images/ipnos/ipnos-hero.png";
import IpnosCover from "../../images/ipnos/ipnos-cover.png";
import IpnosComponents from "../../images/ipnos/ipnos-conponents.png";
import IpnosComponent1 from "../../images/ipnos/ipnos-component-1.png";
import IpnosComponent2 from "../../images/ipnos/ipnos-component-2.png";
import IpnosComponent3 from "../../images/ipnos/ipnos-component-3.png";
import Scenario1 from "../../images/ipnos/ipnos-scenario-1.png";
import Scenario2 from "../../images/ipnos/ipnos-scenario-2.png";
import Scenario3 from "../../images/ipnos/ipnos-scenario-3.png";
import Scenario4 from "../../images/ipnos/ipnos-scenario-4.png";
import Scenario5 from "../../images/ipnos/ipnos-scenario-5.png";
import ProductFeatures1 from "../../images/ipnos/ipnos-product-features-1.png";
import ProductFeatures2 from "../../images/ipnos/ipnos-product-features-2.png";
import Prototype from "../../images/ipnos/ipnos-proto-combined.png";
import FinalBuild1 from "../../images/ipnos/ipnos-final-build-1.png";
import FinalBuild2 from "../../images/ipnos/ipnos-final-build-2.png";

//icons
import IconSketch from "../../images/icons/icon-sketch.svg";
import IconSolidworks from "../../images/icons/icon-solidworks.svg";
import IconRaspberryPi from "../../images/icons/icon-raspberrypi.svg";
import IconArduino from "../../images/icons/icon-arduino.svg";

const IpnosProjectPage = () => {
  return (
    <>
      <ProjectIntro
        projectImg={IpnosProjectHero}
        projectName="Ipnos"
        projectDesc="Improving sleep quality"
        projectColor="brandBlack"
      />
      <ProjectOverview
        projectType="University Project - Solo"
        projectDate="Oct 2018 - May 2019"
        projectToolIcon1={IconSketch}
        projectToolName1="Sketch,"
        projectToolIcon2={IconSolidworks}
        projectToolName2="Solidworks,"
        projectToolIcon3={IconRaspberryPi}
        projectToolName3="Raspberry Pi,"
        projectToolIcon4={IconArduino}
        projectToolName4="Arduino"
        width={{ xl: "1264px" }}
        px={{ base: 8, md: 16, xl: 0 }}
      />
      <ProjectContentContainer>
        <ProjectSection>
          <Box backgroundColor="brandDarkGrey">
            <Image src={IpnosCover} />
          </Box>
        </ProjectSection>
        <ProjectSection>
          <Box mb={20}>
            <Heading3>Introduction</Heading3>
            <BodyText>
              For my MEng final-year project while studying at Imperial College
              London, I developed Ipnos; a non-invasive, smart-home integrated
              system for improving sleep quality. Designed for at-home use,
              Ipnos presents an opportunity for long-term, consumer-centred
              health monitoring and enhancement.
            </BodyText>
          </Box>
          <Box mb={12}>
            <Heading4>The importance of sleep</Heading4>
            <TextContainerWithBackground>
              <BodyText textAlign="center" fontSize="lg">
                The shorter your sleep, the shorter your lifespan
              </BodyText>
            </TextContainerWithBackground>
            <BodyText mb={8}>
              Sleep is crucial in sustaining people’s physiological and
              psychological welfare. Healthy sleep, both in terms of length and
              quality, plays a vital role in regulating the body’s circadian
              rhythm and is linked to multiple socio-physical benefits,
              including weight management, mood regulation and increased
              productivity.
            </BodyText>
            <BodyText mb={8}>
              However, sleep is often sacrificed in favour of familial, social
              or professional responsibilities.
            </BodyText>
          </Box>
          <Flex flexDir={{ base: "column", md: "row" }} alignItems="flex-start">
            <Flex
              px={{ base: 0, sm: 10, lg: 20 }}
              justifyContent="center"
              alignItems="center"
              flex="0 0 50%"
              flexDir="column"
              mb={{ base: 16, md: 0 }}
            >
              <Text fontSize="6xl" color="gray.100">
                21%
              </Text>
              <BodyText color="gray.400">
                Percentage of British people getting the recommended eight hours
                of sleep per night
              </BodyText>
            </Flex>
            <Flex
              px={{ base: 0, sm: 10, lg: 20 }}
              // justifyContent="center"
              alignItems="center"
              flex="0 0 50%"
              flexDir="column"
            >
              <Flex alignItems="baseline">
                <Text fontSize="6xl" color="gray.100" mr={2}>
                  £40
                </Text>
                <Text fontSize="4xl" color="gray.100">
                  billion
                </Text>
              </Flex>
              <BodyText color="gray.400">
                Lack of sleep among U.K. workers costs the economy up to £40
                billion a year in lost productivity
              </BodyText>
            </Flex>
          </Flex>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Healthy sleep
            </ProjectSectionTitle>
          </Box>
          <TextContainerWithBackground mb={16}>
            <BodyText textAlign="center" fontSize="lg">
              How might we improve quality of sleep in the home?{" "}
            </BodyText>
          </TextContainerWithBackground>
          <Stack spacing={8} mb={16}>
            <BodyText>
              Sleep quality is affected by numerous factors, including evening
              exposure to blue light from electronic devices, the bedroom’s
              thermal environment and a healthy bedtime routine.{" "}
            </BodyText>
            <BodyText>Ipnos was designed using 3 key principles:</BodyText>
          </Stack>
          <Stack spacing={20}>
            <IpnosPrinciple
              principleHeading="Personalised"
              principleDesc="Sleep quality and needs are unique to each person. These are
              affected by various factors including age, genetics, the
              bedroom’s thermal environment and a healthy bedtime routine."
            />
            <IpnosPrinciple
              principleHeading="Non-invasive"
              principleDesc="Invasive (e.g. sleep labs) or uncomfortable (e.g. tight wrist
                bands) methods of assessing and improving sleep quality often
                disrupt the user’s sleep."
            />
            <IpnosPrinciple
              principleHeading="Scalable"
              principleDesc="By integrating with smart home devices, products can be
              adapted and scaled to function optimely based on the devices
              within the contraints of a user’s home."
            />
          </Stack>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Measuring and improving sleep quality
            </ProjectSectionTitle>
          </Box>
          <Box mb={16}>
            <BodyText>
              Ipnos is a system that utilises recent technological innovations
              to improve sleep quality and enable the user to establish a
              healthy sleep routine.{" "}
            </BodyText>
          </Box>
          <Image src={IpnosComponents} mb={20} />
          <BodyText mb={8}>It is comprised of three components:</BodyText>
          <Stack spacing={20}>
            <IpnosSystemComponent
              componentHeading="1. Bedside Product"
              componentDesc="Integrating environmental monitoring and data analysis"
              componentImage={IpnosComponent1}
            />
            <IpnosSystemComponent
              componentHeading="2. Sleep Monitor"
              componentDesc="Tracking HR (Heart Rate) using PPG (photoplethysmography)
              sensor technology"
              componentImage={IpnosComponent2}
            />
            <IpnosSystemComponent
              componentHeading="3. Environmental Actuation"
              componentDesc="The system is compatible with smart lighting (Philips Hue)
              and smart thermostat (Nest) appliances.
              The system alters the user’s environment leading up to,
              during and immediately following sleep to promote healthy
              sleep"
              componentImage={IpnosComponent3}
            />
          </Stack>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={32}>
            <ProjectSectionTitle dotColor="brandOrange">
              User Scenarios
            </ProjectSectionTitle>
            <BodyText>
              The following scenarios illustarte the different functions of the
              automatic environmental actuation system. These scenarios progress
              chronologically over the course of a night.
            </BodyText>
          </Box>
          <Stack spacing={{ base: 32, md: 64 }}>
            <Box>
              <Text fontSize="2xl" color="gray.200" mb={8}>
                Phase I: Pre-sleep{" "}
              </Text>
              <IpnosScenario
                scenarioNumber="1"
                scenarioDesc="Alex is reading in bed. Exposure to evening light inhibits
                the naturally timed rise of melatonin, which delays the
                onset of the body's transition to sleep and sleep itself."
                scenarioActions="Nest: Gradual temperature decrease. Ipnos: Provides red light therapy. Red light is ideal
                for evenings because it has a low color temperature."
                scenarioOutcome="The body is eased into its sleep cycle more naturally and
                Alex falls asleep faster."
                scenarioImage={Scenario1}
              />
            </Box>
            <Box>
              <Text fontSize="2xl" color="gray.200" mb={8}>
                Phase II - During sleep{" "}
              </Text>
              <IpnosScenario
                scenarioNumber="2"
                scenarioDesc="Lucy has fallen asleep with lights on in her bedroom.
                Exposure to light affects her sleep quality, due to its
                ability to prevent the release of melatonin, a sleep
                regulating hormone."
                scenarioActions="As soon as Ipnos detects that Lucy has entered the first
                stage of sleep, the lights are turned off automatically."
                scenarioOutcome="With her sleep no longer disturbed by the bright light,
                Lucy enjoys a higher quality, regenerative sleep."
                scenarioImage={Scenario2}
              />
            </Box>
            <IpnosScenario
              scenarioNumber="3"
              scenarioDesc="Lucy wakes up during the night due to noise disturbances.
              These may be sudden, such as the sound of an ambulance siren
              driving by, or longer lasting, e.g. a neighbours washing
              machine."
              scenarioActions="Ipnos records the noise. White noise may be played
              through a smart speaker to block out continuous noise. Temperature is lowered to ease user back to sleep."
              scenarioOutcome="Low temperatures help Lucy fall back asleep, while the
              sound analysis provides actionable feedback in the
              morning."
              scenarioImage={Scenario3}
            />
            <IpnosScenario
              scenarioNumber="4"
              scenarioDesc="Lucy is asleep and appears to be transitioning through the
              sleep stages as expected. However, sleep quality can be
              improved through sleep-stage dependent thermo-regulation."
              scenarioActions="After 4.5 hrs of sleep, Ipnos’ algorithm increases the
              initial temperature by 1 K. Then, Ipnos alters the room’s temperature according to
              the user’s sleep stage."
              scenarioOutcome="Sleep quality is improved through sleep-stage dependent
              thermo-regulation."
              scenarioImage={Scenario4}
            />
            <Box>
              <Text fontSize="2xl" color="gray.200" mb={8}>
                Phase III - Wake up{" "}
              </Text>
              <IpnosScenario
                scenarioNumber="5"
                scenarioDesc="Lucy has been asleep for 7 hours and is approaching desired
                wake-up period. She is approaching the end of a REM cycle
                and is entering a light sleep stage."
                scenarioActions="Ipnos gently wakes Lucy up in the morning by gradually
                increasing the light intensity, mimicking a sunrise. Using Nest thermostat, the room temperature is gradually
                increased."
                scenarioOutcome="Waking up during light sleep feels like waking up
                naturally without an alarm clock - leaving the user well
                rested."
                scenarioImage={Scenario5}
              />
            </Box>
          </Stack>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Product Features
            </ProjectSectionTitle>
          </Box>
          <Flex flexDirection={{ base: "column", md: "row" }}>
            <Flex
              flexDirection="column"
              flex="0 0 50%"
              mb={{ base: 12, md: 0 }}
            >
              <Image src={ProductFeatures1} mb={8} />
              <Image src={ProductFeatures2} />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              pl={{ base: 0, md: 10 }}
            >
              <Box mb={{ base: 8, md: 0 }}>
                <Heading4 mb={{ base: 1, lg: 4 }}>Smart Light Bulb</Heading4>
                <BodyText color="gray.400">
                  provides light therapy, used to induce sleep in the evening
                  and gradually awake the user in the morning.
                </BodyText>
              </Box>
              <Box mb={{ base: 8, md: 0 }}>
                <Heading4 mb={{ base: 1, lg: 4 }}>Elevated Base</Heading4>
                <BodyText color="gray.400">
                  with ventilation slots on the bottom, cooling the electronics
                  and providing a air path for temperature and humidity readings
                </BodyText>
              </Box>
              <Box mb={{ base: 8, md: 0 }}>
                <Heading4 mb={{ base: 1, lg: 4 }}>Design</Heading4>
                <BodyText color="gray.400">
                  sleek, professional design using natural wood and dark
                  colours.{" "}
                </BodyText>
              </Box>
              <Box mb={{ base: 8, md: 0 }}>
                <Heading4 mb={{ base: 1, lg: 4 }}>Casing</Heading4>
                <BodyText color="gray.400">
                  housing the processor and environmental sensors
                </BodyText>
              </Box>
              <Box>
                <Heading4 mb={{ base: 1, lg: 4 }}>Compartment</Heading4>
                <BodyText color="gray.400">
                  for charging and out-of-sight storage of mobile phone
                </BodyText>
              </Box>
            </Flex>
          </Flex>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Physical Prototyping
            </ProjectSectionTitle>
            <BodyText>
              The environmental monitoring system was developed from an initial
              electronics prototype into an integrated, to-scale product
              embodiment. This process involved Solidworks CAD visualisation,
              laser cutting and product assembly.
            </BodyText>
          </Box>
          <Box mb={32}>
            <Image src={Prototype} />
          </Box>
          <Heading3>Final Build</Heading3>
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box flexBasis="48%" mb={{ base: 16, md: 0 }}>
              <Image src={FinalBuild1} />
            </Box>
            <Box flexBasis="48%">
              <Image src={FinalBuild2} />
            </Box>
          </Flex>
        </ProjectSection>
      </ProjectContentContainer>
    </>
  );
};

export default IpnosProjectPage;
