import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@chakra-ui/core";

const MenuItem = (props) => {
  const { children, isLast, to = "/", ...rest } = props;
  return (
    <Text my={4} color="gray.200" {...rest} fontSize="xl">
      <Link to={to}>{children}</Link>
    </Text>
  );
};

export default MenuItem;
