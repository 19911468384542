import React from "react";
import { Box } from "@chakra-ui/core";

const ProjectSectionDot = ({ dotColor, ...rest }) => {
  return (
    <Box
      height="38px"
      width="38px"
      backgroundColor={dotColor}
      borderRadius="50%"
      marginRight={6}
      {...rest}
    ></Box>
  );
};

export default ProjectSectionDot;
