import React from "react";
import {
  Flex,
  Box,
  Heading,
  Image,
  Stack,
  List,
  ListItem,
  Link,
} from "@chakra-ui/core";

// components
import ProjectContentContainer from "../ProjectContentContainer";
import TextContainerWithBackground from "../TextContainerWithBackground";
import CV from "../../images/about/CV_FrancoisCostaPeretti.pdf";

//
import { HEADER_HEIGHT } from "../Header/Header";

// typography
import BodyText from "../Typography/BodyText";

// images
import Headshot from "../../images/about/photo.jpg";
import Cooking from "../../images/about/cooking.png";
import Trekking from "../../images/about/trekking.png";
import Reading from "../../images/about/book.png";

const AboutPage = () => {
  return (
    <>
      <Flex
        backgroundColor="brandDarkGrey"
        height={`calc(100vh - ${HEADER_HEIGHT}px)`}
        alignItems="center"
      >
        <ProjectContentContainer py={24}>
          <Heading
            color="gray.200"
            fontSize={{ base: "48px", sm: "72px", md: "96px" }}
          >
            Crafting digital experiences, collaboratively.
          </Heading>
        </ProjectContentContainer>
      </Flex>
      <ProjectContentContainer>
        <Box mt={{ base: 20, lg: 40 }} mb={{ base: 20, lg: 40 }}>
          <Heading fontWeight="semibold" color="gray.200" size="xl" mb={8}>
            A little about me...
          </Heading>
          <Flex
            mb={16}
            alignItems="center"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex pr={{ base: 0, md: 10 }}>
              <BodyText>
                Hi! I’m Francois, but my friends mostly call me FB (you can too
                😄). <br />
                <br />I grew up as a twin in London to French and Greek parents,
                who exposed us to various cultures, cuisines and art.
                <br />
                <br />
                In 2019, I graduated from Imperial College London with an MEng
                in Design Engineering.
                <br />
                <br />
                Currently, I’m working as an Interaction Designer within the
                lovely UX team at Secret Escapes.
              </BodyText>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent={{ base: "center", md: "flex-end" }}
              mt={{ base: 16, md: 0 }}
            >
              <Image width={{ base: "50%", md: "80%" }} src={Headshot} />
            </Flex>
          </Flex>
          <Flex
            backgroundColor="brandDarkGrey"
            // borderWidth="2px"
            justifyContent="space-between"
            borderRadius={1}
            px={8}
            py={4}
            mb={0}
          >
            <Link href={CV} isExternal>
              <BodyText>Resume ↗</BodyText>
            </Link>
            <Link
              href="https://uk.linkedin.com/in/francois-baptiste-costa-peretti-488a75112"
              isExternal
            >
              <BodyText>LinkedIn ↗</BodyText>
            </Link>
            <Link href="mailto: f.costaperetti@gmail.com">
              <BodyText>Email ↗</BodyText>
            </Link>
          </Flex>
        </Box>

        <Box mt={{ base: 20, lg: 40 }} mb={{ base: 20, lg: 40 }}>
          <Heading fontWeight="semibold" color="gray.200" size="xl" mb={16}>
            In my spare time I...
          </Heading>
          {/* <Flex flexDir={{ base: "column", md: "row" }}> */}
          <Stack
            spacing={{ base: 20, sm: 0 }}
            flexDir={{ base: "column", sm: "row" }}
          >
            <Box p={2}>
              <Image mb={4} src={Cooking} />
              <BodyText>Cook “delicious” meals</BodyText>
              <BodyText>- my very honest girlfriend</BodyText>
            </Box>
            <Box p={2}>
              <Image mb={4} src={Trekking} />
              <BodyText>Trek up to 4600m altitude in Peru </BodyText>
              <BodyText> ~ 5 mins before I collapsed</BodyText>
            </Box>
            <Box p={2}>
              <Image mb={4} src={Reading} />
              <BodyText>
                Level-up my thinking and skillset through books & online courses
              </BodyText>
            </Box>
          </Stack>
        </Box>
        <Box mt={{ base: 20, lg: 40 }} mb={{ base: 20, lg: 40 }}>
          <Heading fontWeight="semibold" color="gray.200" size="xl" mb={16}>
            My design philosophy
          </Heading>
          <Stack spacing={8}>
            <TextContainerWithBackground px={12}>
              <BodyText>
                I believe good design enables people to grow and become better
                versions of themselves.
              </BodyText>
            </TextContainerWithBackground>
            <BodyText>
              It’s clear to me that user-centred products and services have the
              power to significantly improve the lives of users. To this end, I
              aim to use my background in human-centered design to create
              inclusive products and services that are easy to use and
              anticipate user needs, giving users the platform to grow.
            </BodyText>
            <BodyText>
              I benefit from these types of products daily; Gousto gives me the
              opportunity to spend more time with my girlfriend while cooking
              exciting new recipes. Airbnb on the other hand has allowed my
              friends and I to become more independent, knowing there is a safe,
              affordable method for finding accomodation while travelling
              abroad.
            </BodyText>
            <BodyText>What’s next? I want to find out.</BodyText>
          </Stack>
        </Box>
        <Box mt={{ base: 20, lg: 40 }} mb={{ base: 20, lg: 40 }}>
          <Heading fontWeight="semibold" color="gray.200" size="xl" mb={16}>
            Growth &#62; Static
          </Heading>
          <Stack spacing={8}>
            <BodyText>
              I love broadening my skillset and interests through personal
              projects and challenges. These can be are practical (e.g. learning
              to code this portfolio in React) or more random (e.g. memorising
              the order of a shuffled deck of cards in under 5 minutes - and
              yes, I still forget where my keys are...).
            </BodyText>
            <Box>
              <BodyText mb={4}>At the moment I’m learning:</BodyText>
              <TextContainerWithBackground mb={0}>
                <List styleType="disc" stylePos="outside">
                  <ListItem
                    color="gray.200"
                    lineHeight="tall"
                    paddingBottom={1}
                  >
                    Design/UX: Psychology in UX design
                  </ListItem>
                  <ListItem
                    color="gray.200"
                    lineHeight="tall"
                    paddingBottom={1}
                  >
                    Coding: Combining Typescript, React and Chakra UI
                  </ListItem>
                </List>
              </TextContainerWithBackground>
            </Box>
            <Box>
              <BodyText mb={4}>Next up:</BodyText>
              <TextContainerWithBackground mb={0}>
                <List styleType="disc" stylePos="outside">
                  <ListItem
                    color="gray.200"
                    lineHeight="tall"
                    paddingBottom={1}
                  >
                    A/B testing
                  </ListItem>
                </List>
              </TextContainerWithBackground>
            </Box>
          </Stack>
        </Box>
      </ProjectContentContainer>
    </>
  );
};

export default AboutPage;
