import React from "react";
import styled from "styled-components";
import { Box, Text, Flex } from "@chakra-ui/core";

import HomePageProject from "./Project/Project";
import ProjectOverview from "../ProjectOverview";
import ProjectSection from "../ProjectSection";

//image
import LoopImage from "../../images/loop/loop-project-hero.png";
import FlickImage from "../../images/flick/flick-hero.png";
import IpnosImage from "../../images/ipnos/ipnos-hero.png";

//icons
import IconSketch from "../../images/icons/icon-sketch.svg";
import IconSolidworks from "../../images/icons/icon-solidworks.svg";
import IconRaspberryPi from "../../images/icons/icon-raspberrypi.svg";
import IconArduino from "../../images/icons/icon-arduino.svg";
import IconReact from "../../images/icons/icon-react.svg";
import IconFigma from "../../images/icons/icon-figma.svg";

const ContentContainer = styled.div`
  max-width: 1264px;
  margin: 0 auto;
  padding: 0px 32px;
`;

const App = () => {
  return (
    <div>
      <ContentContainer>
        <Box paddingY={32} borderBottomWidth="1px" borderBottomColor="gray.400">
          <Text
            fontSize="5xl"
            marginBottom={20}
            color="gray.300"
            fontWeight="medium"
          >
            Hey, I’m Francois, a product designer, engineer & creative with a
            love for technology
          </Text>
          <Text fontSize="2xl" color="gray.300">
            I create empathetic solutions to problems through user-centred
            design and interdisciplinary work
          </Text>
        </Box>
        <ProjectSection>
          <Flex flexDirection="column">
            <HomePageProject
              projectImage={LoopImage}
              projectTitle="Loop"
              projectDesc="I designed and built Loop as a unofficial, alternative reader for
          Hacker News"
              projectURL="/loop-project/"
              projectColor="brandOrange"
            />
            <ProjectOverview
              projectType="Product Designer"
              projectDate="May 2020 - Now"
              projectToolIcon1={IconSketch}
              projectToolName1="Sketch,"
              projectToolIcon2={IconFigma}
              projectToolName2="Figma,"
              projectToolIcon3={IconReact}
              projectToolName3="React"
              borderBottom="0px"
              height={{ md: "auto" }}
              mx={0}
              mt={12}
            />
          </Flex>
        </ProjectSection>
        <ProjectSection>
          <Flex flexDirection="column">
            <HomePageProject
              projectImage={FlickImage}
              projectTitle="Flick"
              projectDesc="I worked with Flick to build a consistent and intuitive experience for users, while establishing a scalable design system"
              projectURL="/flick-project/"
              projectColor="brandBlue"
            />
            <ProjectOverview
              projectType="Freelance - Interaction Designer"
              projectDate="Sep 2019 - Jan 2020"
              projectToolIcon1={IconSketch}
              projectToolName1="Sketch,"
              projectToolIcon2={IconFigma}
              projectToolName2="Figma,"
              projectToolIcon3={IconReact}
              projectToolName3="React"
              borderBottom="0px"
              height={{ md: "auto" }}
              mx={0}
              mt={12}
            />
          </Flex>
        </ProjectSection>
        <ProjectSection>
          <Flex flexDirection="column">
            <HomePageProject
              projectImage={IpnosImage}
              projectTitle="Ipnos"
              projectDesc="For my MEng final-year project at Imperial College London, I worked on a non-invasive, smart-home integrated system for improving sleep quality."
              projectURL="/ipnos-project/"
              projectColor="brandBlack"
            />
            <ProjectOverview
              projectType="University Project - Solo"
              projectDate="Oct 2018 - May 2019"
              projectToolIcon1={IconSketch}
              projectToolName1="Sketch,"
              projectToolIcon2={IconSolidworks}
              projectToolName2="Solidworks,"
              projectToolIcon3={IconRaspberryPi}
              projectToolName3="Raspberry Pi,"
              projectToolIcon4={IconArduino}
              projectToolName4="Arduino"
              borderBottom="0px"
              height={{ md: "auto" }}
              mx={0}
              mt={12}
            />
          </Flex>
        </ProjectSection>
      </ContentContainer>
    </div>
  );
};

export default App;
