import React from "react";
import { PseudoBox, BoxProps } from "@chakra-ui/core";

const ProjectSection = ({ children, ...rest }: BoxProps) => {
  return (
    <PseudoBox
      borderBottom="1px solid rgba(255, 255, 255, 0.1)"
      mt={{ base: 20, lg: 32 }}
      pb={{ base: 20, lg: 32 }}
      _last={{ marginBottom: "0" }}
      {...rest}
    >
      {children}
    </PseudoBox>
  );
};

export default ProjectSection;
