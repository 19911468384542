import React from "react";
import { Text, Image, Flex, FlexProps } from "@chakra-ui/core";

import TickIcon from "../../../images/icons/icon-tick.svg";

interface ChecklistItemYesProps extends FlexProps {
  text: string;
}

const ChecklistItemYes = ({ text, ...rest }: ChecklistItemYesProps) => {
  return (
    <Flex
      backgroundColor="brandDarkGrey"
      padding={4}
      borderRadius={1}
      alignItems="center"
      {...rest}
    >
      <Flex marginRight={3} alignItems="center" flex="0 0 30px">
        <Image src={TickIcon} />
      </Flex>
      <Text color="gray.200">{text}</Text>
    </Flex>
  );
};

export default ChecklistItemYes;
