import React from "react";
import { Box, Flex, Stack, Text, List, ListItem, Image } from "@chakra-ui/core";

import ProjectContentContainer from "../ProjectContentContainer";
import ProjectOverview from "../ProjectOverview";
import ProjectIntro from "../ProjectIntro";
import ProjectSectionTitle from "../ProjectSectionTitle/ProjectSectionTitle";
import ProjectSection from "../ProjectSection";
import TextContainerWithBackground from "../TextContainerWithBackground";
import ChecklistItemYes from "./CheckListItemYes/ChecklistItemYes";
import ChecklistItemNo from "./CheckListItemNo/ChecklistItemNo";

// typography
import BodyText from "../Typography/BodyText";
import Heading3 from "../Typography/Heading3";
import Heading4 from "../Typography/Heading4";

// images
import LoopCover from "../../images/loop/loop-cover.png";
import FeaturesWorkWell from "../../images/loop/features-that-work-well.png";
import FeaturesToImprove from "../../images/loop/FeaturesToImprove.png";
import LoopIdeation1 from "../../images/loop/loop-ideation1.png";
import LoopIdeation2 from "../../images/loop/loop-ideation2.png";
import FinalDesign from "../../images/loop/final-design.png";
import LoopProjectHero from "../../images/loop/loop-project-hero.png";

//gifs
import embedGIF from "../../images/loop/gifs/loop-f1.gif";
import scrollGIF2 from "../../images/loop/gifs/loop-f2.gif";
import relatedGIF2 from "../../images/loop/gifs/loop-f3.gif";
import viewGIF from "../../images/loop/gifs/loop-f4.gif";

//icons
import IconReact from "../../images/icons/icon-react.svg";
import IconSketch from "../../images/icons/icon-sketch.svg";
import IconFigma from "../../images/icons/icon-figma.svg";

//----------- CONTAINERS --------------------

const LoopProjectPage = () => {
  return (
    <>
      <ProjectIntro
        projectImg={LoopProjectHero}
        projectName="Loop"
        projectDesc="Hacker News reader"
        projectColor="brandOrange"
      />
      <ProjectOverview
        projectType="Product Designer"
        projectDate="May 2020 - Now"
        projectToolIcon1={IconSketch}
        projectToolName1="Sketch,"
        projectToolIcon2={IconFigma}
        projectToolName2="Figma,"
        projectToolIcon3={IconReact}
        projectToolName3="React"
        flexDirection={{ base: "column", md: "row" }}
        width={{ xl: "1264px" }}
        px={{ base: 8, md: 16, xl: 0 }}
      />
      <ProjectContentContainer>
        <ProjectSection>
          <Box backgroundColor="brandDarkGrey">
            <Image src={LoopCover} />
          </Box>
        </ProjectSection>
        <ProjectSection>
          <Box mb={12}>
            <Heading3>Introduction</Heading3>
            <BodyText>
              Loop began as a submission to a design challenge while
              interviewing for the Interaction Designer position at Secret
              Escapes. With a 3-day deadline, I used a condensed sprint
              methodology, iterating quickly while ensuring validation of
              designs through user testing.
              <br />
              <br />
              Since then I have expanded on the work I put forward, designing
              and building (WIP) a functional MVP using Figma and React.{" "}
            </BodyText>
          </Box>
          <Box>
            <Heading3>Challenge</Heading3>
            <BodyText>
              “The Y Combinator product team are looking to improve two business
              metrics: article clicks per session and time spent on site, while
              making the site visually stunning.
              <br />
              <br />
              Design a new homepage for the website.”
            </BodyText>
          </Box>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Understand
            </ProjectSectionTitle>
            <BodyText>
              As an important foundational step, I began by critically analysing
              the purpose of Hacker News and its value proposition. Then I spoke
              to two regular users and tested the platform’s usability on
              friends that work/study in tech but are not regular users to
              identify pain points.
            </BodyText>
          </Box>
          <>
            <Heading4>Platform</Heading4>
            <TextContainerWithBackground>
              <List styleType="disc" stylePos="outside">
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  Hacker News is a platform providing tech-related links to
                  content for its tech-savvy community
                </ListItem>
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  Hacker News uses outbound links, so once visitors click on a
                  link, they are redirected out of Hacker News domain
                </ListItem>
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  There is a big emphasis on comments{" "}
                </ListItem>
              </List>
            </TextContainerWithBackground>
          </>
          <>
            <Heading4>Users</Heading4>
            <BodyText pb={1}>Regular users</BodyText>
            <TextContainerWithBackground>
              <List styleType="disc" stylePos="outside">
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  They spend 5-10 mins each day in the morning browsing the
                  homepage and saving interesting links to their bookmarks to
                  consume later
                </ListItem>
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  Having easy access to comments is crucial
                </ListItem>
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  Link hosting display helps them determine type of content
                  (e.g. github depository vs news article)
                </ListItem>
              </List>
            </TextContainerWithBackground>
            <BodyText pb={1}>New users</BodyText>
            <TextContainerWithBackground mb={0}>
              <List styleType="disc" stylePos="outside">
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  They were confused by how to search for content and left the
                  site
                </ListItem>
                <ListItem color="gray.200" lineHeight="tall" paddingBottom={1}>
                  Top stories enabled them to discover new, interesting content
                </ListItem>
              </List>
            </TextContainerWithBackground>
          </>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Platform analysis
            </ProjectSectionTitle>
            <BodyText>
              During this stage, the existing Hacker News platform was analysed
              to uncover the most evident issues, identify potential
              improvements and prioritise changes.
            </BodyText>
          </Box>
          <Box mb={32}>
            <Heading4 mb={16}>Features that work well</Heading4>
            <Image src={FeaturesWorkWell} />
          </Box>
          <Box>
            <Heading4>Features that could be improved</Heading4>
            <Image src={FeaturesToImprove} />
          </Box>
        </ProjectSection>

        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Ideation
            </ProjectSectionTitle>
            <BodyText>
              As part of the ideation phase, I created 2 design mockups,
              implementing the new features and addressing the user pain points
              while evaluating the designs against the metrics of interest:
              article clicks per session and time spent on site. <br />
              Then, I user tested the designs with the regular and new users,
              adapting the designs based on feedback.{" "}
            </BodyText>
          </Box>
          <Heading3>Evaluating the design direction</Heading3>

          <Flex mb={20} flexDirection={{ base: "column", lg: "row" }}>
            <Stack spacing={8} pr={{ base: 0, lg: 3 }} mb={{ base: 20, lg: 0 }}>
              <Heading4>Option A</Heading4>
              <Image src={LoopIdeation1} />
              <ChecklistItemYes text="Related content suggestions" />
              <ChecklistItemNo
                text="Tags would require posters to tag content or complex tech
                  implementation"
              />
              <ChecklistItemNo text="Article navigation difficult" />
            </Stack>
            <Stack spacing={8} pl={{ base: 0, lg: 3 }}>
              <Heading4>Option B</Heading4>
              <Image src={LoopIdeation2} />
              <ChecklistItemYes text="Related content suggestions" />
              <ChecklistItemYes
                text="Articles are embedded within Hacker News instead of
                  redirecting to external link"
              />
              <ChecklistItemYes text="Improve access to comments - using a switch" />
            </Stack>
          </Flex>
          <Box marginTop={20}>
            <BodyText>
              After evaluating the performance of both designs against the high
              priority improvements, I decided to proceed with developing Design
              B further.
            </BodyText>
          </Box>
        </ProjectSection>

        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Final design
            </ProjectSectionTitle>
          </Box>
          <Stack spacing={{ base: 48, lg: 64 }}>
            <Image src={FinalDesign} />

            <Flex flexDirection={{ base: "column", lg: "row" }}>
              <Flex
                flexDirection="column"
                flex="0 0 35%"
                justifyContent="center"
                pr={{ base: 0, lg: 20 }}
                pb={{ base: 16, lg: 0 }}
              >
                <Heading3>Embedded viewing</Heading3>
                <Text fontSize="lg" lineHeight="tall" color="gray.300">
                  The stories are embedded within Loop. Users are no longer
                  redirected to other websites, significantly increasing time
                  spent on site. This functionality can be achieved using an
                  iframe HTML component.
                </Text>
              </Flex>
              <Box flex="0 0 65%">
                <Image width="100%" src={embedGIF} />
              </Box>
            </Flex>

            <Flex flexDirection={{ base: "column", lg: "row" }}>
              <Box flex="0 0 65%" order={{ base: 4, lg: 0 }}>
                <Image width="100%" src={scrollGIF2} />
              </Box>
              <Flex
                flexDirection="column"
                flex="0 0 35%"
                justifyContent="center"
                pl={{ base: 0, lg: 20 }}
                pb={{ base: 16, lg: 0 }}
              >
                <Heading3>Article scroll</Heading3>
                <Text fontSize="lg" lineHeight="tall" color="gray.300">
                  Makes it easy for users to quickly scroll through and view
                  different posts. The scroller loads 30 posts at a time through
                  an asynchronous call to the Hacker News API.{" "}
                </Text>
              </Flex>
            </Flex>

            <Flex flexDirection={{ base: "column", lg: "row" }}>
              <Flex
                flexDirection="column"
                flex="0 0 35%"
                justifyContent="center"
                pr={{ base: 0, lg: 20 }}
                pb={{ base: 16, lg: 0 }}
              >
                <Heading3>Related content</Heading3>
                <Text fontSize="lg" lineHeight="tall" color="gray.300">
                  Upon selecting an article, the user is recommended a related
                  Hacker News article.
                  <br />
                  <br />
                  By introducing the user to content related to one they have
                  already shown interest in, the number of article clicks per
                  session may increase, while also providing value to the user.
                </Text>
              </Flex>
              <Box flex="0 0 65%">
                <Image width="100%" src={relatedGIF2} />
              </Box>
            </Flex>

            <Flex flexDirection={{ base: "column", lg: "row" }}>
              <Box flex="0 0 65%" order={{ base: 4, lg: 0 }}>
                <Image width="100%" src={viewGIF} />
              </Box>
              <Flex
                flexDirection="column"
                flex="0 0 35%"
                justifyContent="center"
                pl={{ base: 0, lg: 20 }}
                pb={{ base: 16, lg: 0 }}
              >
                <Heading3>View type toggle</Heading3>
                <Text fontSize="lg" lineHeight="tall" color="gray.300">
                  Allowing users to easily toggle between Reader view and
                  Comments view
                </Text>
              </Flex>
            </Flex>
          </Stack>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Building in React
            </ProjectSectionTitle>
            <BodyText>
              I am currently in the process of building an MVP for Loop in
              React.
            </BodyText>
          </Box>

          <Heading4>At this stage, I’ve successfully:</Heading4>
          <TextContainerWithBackground>
            <List styleType="disc" stylePos="outside">
              <ListItem color="gray.200" lineHeight="tall" pb={2}>
                Expanded on the inital designs in Figma
              </ListItem>
              <ListItem color="gray.200" lineHeight="tall" pb={2}>
                Built the front-end of the web app home page
              </ListItem>
              <ListItem color="gray.200" lineHeight="tall" pb={2}>
                Retrieved and displayed a list of article cards (including
                title, author, points and timestamp) in the article scroller
                using the Hacker News API
              </ListItem>
              <ListItem color="gray.200" lineHeight="tall">
                Implemented the embedded viewing functionality using React Hooks
                and an iframe HTML tag, allowing users to click on an article
                card and have it displayed in the Viewer.{" "}
              </ListItem>
            </List>
          </TextContainerWithBackground>
          <Heading4>Next steps include:</Heading4>
          <TextContainerWithBackground mb={0}>
            <List styleType="disc" stylePos="outside">
              <ListItem color="gray.200" lineHeight="tall" pb={2}>
                Fix infitinite scroll functionality to load articles in batches
                of 30 and reduce initial loading time
              </ListItem>
              <ListItem color="gray.200" lineHeight="tall" pb={2}>
                Add hover & select states for the article cards
              </ListItem>
              <ListItem color="gray.200" lineHeight="tall" pb={2}>
                Release beta version
              </ListItem>
              <ListItem color="gray.200" lineHeight="tall">
                Run user testing sessions and use analytical tools such as
                Hotjar to uncover pain points, validate design decisions and
                identify valuable features for users
              </ListItem>
            </List>
          </TextContainerWithBackground>
        </ProjectSection>
        <ProjectSection>
          <Box paddingBottom={16}>
            <ProjectSectionTitle dotColor="brandOrange">
              Reflection
            </ProjectSectionTitle>
            <BodyText>
              So far, I’ve had a truly amazing time working on this project -
              and I’m excited to keep working towards an MVP. Initially, the
              design brief allowed me to think critically to fit my design
              solution in just a three-day period. By pursuing the project
              further, I’ve been able to complement the intial design
              exploration with the technical considerations that come into play
              when building a functional product in React.
              <br />
              <br />
              While improving my developer skillset, it has also given me the
              opportunity to understand lean and agile methodologies further, as
              I focus my efforts on developing an MVP that fits the needs of
              users, which I can then iterate on based on early feedback.
            </BodyText>
          </Box>
        </ProjectSection>
      </ProjectContentContainer>
    </>
  );
};

export default LoopProjectPage;
