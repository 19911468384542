import React from "react";
import { Text, Flex, Stack, Image, FlexProps } from "@chakra-ui/core";

export const OVERVIEW_HEIGHT = 128;

interface ProjectOverviewProps extends FlexProps {
  projectType: string;
  projectDate: string;
  projectToolIcon1?: any;
  projectToolName1?: string;
  projectToolIcon2?: any;
  projectToolName2?: string;
  projectToolIcon3?: any;
  projectToolName3?: string;
  projectToolIcon4?: any;
  projectToolName4?: string;
}

const ProjectOverview = ({
  projectType,
  projectDate,
  projectToolIcon1,
  projectToolName1,
  projectToolIcon2,
  projectToolName2,
  projectToolIcon3,
  projectToolName3,
  projectToolIcon4,
  projectToolName4,
  ...rest
}: ProjectOverviewProps) => {
  if (!projectToolName4) {
    return (
      <Stack
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "left", lg: "center" }}
        spacing={{ base: 8, lg: 0 }}
        margin="0 auto"
        paddingY={{ base: 8, lg: 0 }}
        borderBottom="1px solid #2e2e30"
        height={{ base: "auto", lg: 32 }}
        {...rest}
      >
        <Flex>
          <Text fontWeight="bold" color="gray.200" paddingRight={2}>
            Type:
          </Text>
          <Text color="gray.300">{projectType}</Text>
        </Flex>
        <Flex>
          <Text fontWeight="bold" color="gray.200" paddingRight={2}>
            When:
          </Text>
          <Text color="gray.300">{projectDate}</Text>
        </Flex>
        <Flex alignItems="baseline">
          <Text fontWeight="bold" color="gray.200" paddingRight={2}>
            Tools:
          </Text>
          <Flex flexWrap="wrap">
            <Flex alignItems="baseline">
              <Image
                height="18px"
                marginRight={2}
                src={projectToolIcon1}
              ></Image>
              <Text marginRight={2} color="gray.200">
                {projectToolName1}
              </Text>
            </Flex>
            <Flex alignItems="baseline">
              <Image
                height="18px"
                marginRight={2}
                src={projectToolIcon2}
              ></Image>
              <Text marginRight={2} color="gray.200">
                {projectToolName2}
              </Text>
            </Flex>
            <Flex alignItems="baseline">
              <Image
                height="18px"
                marginRight={2}
                src={projectToolIcon3}
              ></Image>
              <Text marginRight={2} color="gray.200">
                {projectToolName3}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Stack>
      // </Box>
    );
  }
  return (
    <Stack
      flexDirection={{ base: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems={{ base: "left", lg: "center" }}
      spacing={{ base: 8, lg: 0 }}
      margin="0 auto"
      paddingY={{ base: 8, lg: 0 }}
      borderBottom="1px solid #2e2e30"
      height={{ base: "auto", lg: 32 }}
      {...rest}
    >
      <Flex>
        <Text fontWeight="bold" color="gray.200" paddingRight={2}>
          Type:
        </Text>
        <Text color="gray.300">{projectType}</Text>
      </Flex>
      <Flex>
        <Text fontWeight="bold" color="gray.200" paddingRight={2}>
          When:
        </Text>
        <Text color="gray.300">{projectDate}</Text>
      </Flex>
      <Flex alignItems="baseline">
        <Text fontWeight="bold" color="gray.200" paddingRight={2}>
          Tools:
        </Text>
        <Flex flexWrap="wrap">
          <Flex alignItems="baseline">
            <Image height="18px" marginRight={2} src={projectToolIcon1}></Image>
            <Text marginRight={2} color="gray.200">
              {projectToolName1}
            </Text>
          </Flex>
          <Flex alignItems="baseline">
            <Image height="18px" marginRight={2} src={projectToolIcon2}></Image>
            <Text marginRight={2} color="gray.200">
              {projectToolName2}
            </Text>
          </Flex>
          <Flex alignItems="baseline">
            <Image height="18px" marginRight={2} src={projectToolIcon3}></Image>
            <Text marginRight={2} color="gray.200">
              {projectToolName3}
            </Text>
          </Flex>

          <Flex alignItems="baseline">
            <Image height="18px" marginRight={2} src={projectToolIcon4}></Image>
            <Text marginRight={2} color="gray.200">
              {projectToolName4}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Stack>
    // </Box>
  );
};

export default ProjectOverview;
