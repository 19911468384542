import React from "react";
import { Flex, Heading } from "@chakra-ui/core";

import ProjectSectionDot from "./Dot/Dot";

const ProjectSectionTitle = ({ dotColor, children }) => {
  return (
    <Flex alignItems="flex-end" paddingBottom={8}>
      <ProjectSectionDot dotColor={dotColor} />
      <Heading as="h3" size="lg" color="gray.100">
        {children}
      </Heading>
    </Flex>
  );
};

export default ProjectSectionTitle;
