import React from "react";
import { Box, Flex, FlexProps } from "@chakra-ui/core";

// typography
import BodyText from "../../Typography/BodyText";
import Heading4 from "../../Typography/Heading4";

interface IpnosPrincipleProps extends FlexProps {
  principleHeading: string;
  principleDesc: string;
}

const IpnosPrinciple = ({
  principleHeading,
  principleDesc,
  ...rest
}: IpnosPrincipleProps) => {
  return (
    <Flex
      alignContent="stretch"
      flexDirection={{ base: "column", sm: "row" }}
      {...rest}
    >
      <Flex
        borderRadius={4}
        border="2px solid #1F2024"
        flex="0 0 30%"
        alignItems="center"
        justifyContent="center"
        py={{ base: 6, sm: 0 }}
        mb={{ base: 4, sm: 0 }}
      >
        <Heading4 m={0}>{principleHeading}</Heading4>
      </Flex>
      <Box px={{ base: 0, sm: 8 }}>
        <BodyText color="gray.400">{principleDesc}</BodyText>
      </Box>
    </Flex>
  );
};

export default IpnosPrinciple;
