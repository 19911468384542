import React from "react";
import { Text, Image, Flex } from "@chakra-ui/core";

import CrossIcon from "../../../images/icons/icon-cross.svg";

const ChecklistItemNo = ({ text, ...rest }) => {
  return (
    <Flex
      backgroundColor="brandDarkGrey"
      padding={4}
      borderRadius={1}
      alignItems="center"
      {...rest}
    >
      <Flex marginRight={3} alignItems="center" flex="0 0 30px">
        <Image src={CrossIcon} />
      </Flex>
      <Text color="gray.200">{text}</Text>
    </Flex>
  );
};

export default ChecklistItemNo;
