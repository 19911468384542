import React from "react";
import { Box, Image } from "@chakra-ui/core";

// components
import ProjectIntro from "../ProjectIntro";
import ProjectContentContainer from "../ProjectContentContainer";
import ProjectSectionTitle from "../ProjectSectionTitle/ProjectSectionTitle";
import ProjectSection from "../ProjectSection";
import ProjectOverview from "../ProjectOverview";

// typography
import BodyText from "../Typography/BodyText";
import Heading3 from "../Typography/Heading3";

// images
import FlickProjectHero from "../../images/flick/flick-hero.png";
import FlickCover from "../../images/flick/flick-cover.png";
import DesignSystem from "../../images/flick/DS.png";
import AnalysisBefore from "../../images/flick/analysis-before.png";
import AnalysisAfter from "../../images/flick/analysis-after.png";

//icons
import IconReact from "../../images/icons/icon-react.svg";
import IconSketch from "../../images/icons/icon-sketch.svg";
import IconFigma from "../../images/icons/icon-figma.svg";

const FlickProjectPage = () => {
  return (
    <>
      <ProjectIntro
        projectImg={FlickProjectHero}
        projectName="Flick"
        projectDesc="Instagram hashtag tool"
        projectColor="brandBlue"
      />
      <ProjectOverview
        projectType="Freelance - Interaction Designer"
        projectDate="Sep 2019 - Jan 2020"
        projectToolIcon1={IconSketch}
        projectToolName1="Sketch,"
        projectToolIcon2={IconFigma}
        projectToolName2="Figma,"
        projectToolIcon3={IconReact}
        projectToolName3="React"
        // flexDirection={{ base: "column", md: "row" }}
        width={{ xl: "1264px" }}
        px={{ base: 8, md: 16, xl: 0 }}
      />
      <ProjectContentContainer>
        <ProjectSection>
          <Box backgroundColor="brandDarkGrey" padding={8}>
            <Image src={FlickCover} />
          </Box>
        </ProjectSection>
        <ProjectSection>
          <Box mb={12}>
            <Heading3>Introduction</Heading3>
            <BodyText>
              I joined Flick in a freelance role as the company reached
              product-market fit. During their first phase, the company had
              focused on developing the product, testing it and improving the
              platform by bringing out valuable features based on user feedback.
              However, as this transition happened very quickly, there were
              several inconsistencies affecting the UX of the platform.{" "}
            </BodyText>
          </Box>
          <Box>
            <Heading3>Challenge</Heading3>
            <BodyText>
              My work consisted of transforming the company's user interface
              into a consistent and intuitive experience by formalising a design
              system while maintaining Flick’s brand identity.
              <br />
              <br />
              My responsibilities included analysing aspects of the current
              platform that could be improved, prototyping and testing solutions
              for these and building a consistent design library.
              <br />
              <br />
              During this time I worked directly with the frontend development
              team, acquiring a knowledge of best practices used in industry
              while building the design library in Sketch and React in
              Storybook.{" "}
            </BodyText>
          </Box>
        </ProjectSection>
        <ProjectSection>
          <Box pb={16}>
            <ProjectSectionTitle dotColor="brandBlue">
              UX Analysis
            </ProjectSectionTitle>
            <BodyText>
              We began by analysing Flick's platform and identifying
              inconsistent components and responses to user interaction. Among
              the inconsistencies we noted were differing hover/active state
              responses of form elements and a confusing number of repeated
              buttons within a single page that, when clicked, achieved the same
              result. Furthermore, we collected and categorised all types of
              page elements, covering typography, iconography and navigation.
              <br />
              <br />
              Additionally, we were able to use insights gathered from analytics
              software FullStory to identify issues such as buttons not being
              used at all, consequently bringing no value to users. Often, this
              was because the same outcome could be achieved using a different
              method. Or the opposite would happen and users would struggle to
              perform key actions because the user flow was overly complex.
            </BodyText>
          </Box>
          <Box mb={32}>
            <Heading3 mb={16}>Platform audit</Heading3>
            <Image src={AnalysisBefore} />
          </Box>
          <Box>
            <Heading3 mb={16}>New design</Heading3>
            <Image src={AnalysisAfter} />
          </Box>
        </ProjectSection>
        <ProjectSection>
          <Box>
            <ProjectSectionTitle dotColor="brandBlue">
              Design System
            </ProjectSectionTitle>
            <BodyText pb={16}>
              We put together a design system as a long-term solution. The
              objective was that, as the company grew, basic decisions could be
              taken out of the hands of individual designers and developers,
              ensuring a consistent and intuitive experience for users while
              also freeing up time for employees to instead focus on solving
              bigger problems and creating value. We also delivered a
              complimentary dark theme using using Material Design guidelines.
            </BodyText>
            <Box backgroundColor="brandDarkGrey" padding={8}>
              <Image src={DesignSystem} />
            </Box>
          </Box>
        </ProjectSection>
      </ProjectContentContainer>
    </>
  );
};

export default FlickProjectPage;
