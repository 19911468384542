import React from "react";
import { Box, Flex, Image, FlexProps } from "@chakra-ui/core";

// typography
import BodyText from "../../Typography/BodyText";
import Heading4 from "../../Typography/Heading4";

interface IpnosSystemComponentProps extends FlexProps {
  componentHeading: string;
  componentDesc: string;
  componentImage: any;
}

const IpnosSystemComponent = ({
  componentHeading,
  componentDesc,
  componentImage,
  ...rest
}: IpnosSystemComponentProps) => {
  return (
    <Box backgroundColor="brandDarkGrey" p={4} {...rest}>
      <Flex flexDirection={{ base: "column-reverse", md: "row" }}>
        <Flex flex="0 0 40%" alignItems="center" justifyContent="center">
          <Image width="80%" src={componentImage} />
        </Flex>
        <Flex flexDir="column" p={{ base: 4, md: 16 }} justifyContent="center">
          <Heading4 mb={4}>{componentHeading}</Heading4>
          <BodyText color="gray.400">{componentDesc}</BodyText>
        </Flex>
      </Flex>
    </Box>
  );
};

export default IpnosSystemComponent;
