import React from "react";
import { Text, BoxProps } from "@chakra-ui/core";

const BodyText = ({ children, ...rest }: BoxProps) => {
  return (
    <Text fontSize="md" lineHeight="2" color="gray.300" {...rest}>
      {children}
    </Text>
  );
};

export default BodyText;
