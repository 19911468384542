import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/core";

const Heading3 = ({ children, ...rest }: HeadingProps) => {
  return (
    <Heading as="h3" size="lg" color="gray.100" mb={8} {...rest}>
      {children}
    </Heading>
  );
};

export default Heading3;
