import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/core";

const Heading4 = ({ children, ...rest }: HeadingProps) => {
  return (
    <Heading as="h4" size="md" color="gray.200" mb={8} {...rest}>
      {children}
    </Heading>
  );
};

export default Heading4;
