import React from "react";
import {
  Drawer,
  DrawerBody,
  IconButton,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  Box,
  Flex,
  Image,
  Link as LinkChakra,
} from "@chakra-ui/core";

import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import MenuItems from "./MenuItem/MenuItem";
import Logo from "../../images/logo.svg";
import BodyText from "../Typography/BodyText";
import CV from "../../images/about/CV_FrancoisCostaPeretti.pdf";

export const HEADER_HEIGHT = 96;

const Header = ({ ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex
      px={8}
      py={8}
      maxWidth="1264px"
      alignItems="center"
      justify="center"
      margin="0 auto"
      borderBottom="2px solid #"
      {...rest}
    >
      <Flex justify="space-between" width="100%">
        <Link to="/">
          <Image src={Logo} />
        </Link>
        <Box display={{ base: "none", sm: "inline" }}>
          <Flex>
            <Link to="/">
              <BodyText px={4}>Projects</BodyText>
            </Link>
            <Link to="/about/">
              <BodyText px={4}>My story</BodyText>
            </Link>
            <LinkChakra href={CV} isExternal>
              <BodyText px={4}>Resume</BodyText>
            </LinkChakra>
          </Flex>
        </Box>
      </Flex>
      <Box display={{ base: "inline", sm: "none" }}>
        <IconButton
          onClick={onOpen}
          aria-label="Open menu"
          fontSize="20px"
          icon={AiOutlineMenu}
        />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          // finalFocusRef={btnRef}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent bg="#131414">
            <DrawerCloseButton color="gray.200" />
            <DrawerHeader bg="black">
              <Image src={Logo} />
            </DrawerHeader>

            <DrawerBody>
              <Stack my={4} spacing={8}>
                <MenuItems to="/">Projects</MenuItems>
                <MenuItems to="/about/">My story</MenuItems>
                <MenuItems>Resume</MenuItems>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </Flex>
  );
};

export default Header;
