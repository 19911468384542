import React from "react";
import { Box, Flex, FlexProps, Link } from "@chakra-ui/core";

import BodyText from "../Typography/BodyText";
import CV from "../../images/about/CV_FrancoisCostaPeretti.pdf";

const Footer = ({ ...rest }: FlexProps) => {
  return (
    <Flex backgroundColor="#0d0e0e">
      <Flex
        width="1264px"
        justifyContent="space-between"
        margin="0 auto"
        px={8}
        py={12}
        flexWrap="wrap"
        {...rest}
        // flexDir={{ base: "column", lg: "row" }}
        // backgroundColor="#0d0e0e"
      >
        <Box maxWidth="470px" marginY={4}>
          <BodyText fontWeight="bold" mb={2}>
            Thank you
          </BodyText>
          <BodyText fontSize="sm">
            Thank you for visiting and scrolling through my portfolio. If you
            want to talk about any of my projects, or just chat, I'd love to
            hear from you!
          </BodyText>
        </Box>
        <Box marginY={4}>
          <BodyText fontWeight="bold" mb={2}>
            Contact
          </BodyText>
          <BodyText fontSize="sm">+44 7818606470</BodyText>
          <Link href="mailto: f.costaperetti@gmail.com">
            <BodyText fontSize="sm">f.costaperetti@gmail.com</BodyText>
          </Link>
        </Box>
        <Box marginY={4}>
          <BodyText fontWeight="bold" mb={2}>
            Links
          </BodyText>
          <Link href={CV} isExternal>
            <BodyText fontSize="sm">Resume ↗</BodyText>
          </Link>
          <Link
            href="https://uk.linkedin.com/in/francois-baptiste-costa-peretti-488a75112"
            isExternal
          >
            <BodyText fontSize="sm">LinkedIn ↗</BodyText>
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Footer;
