import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import HomePage from "../components/HomePage/HomePage";
import LoopProject from "../components/LoopProjectPage/LoopProjectPage";
import FlickProject from "../components/FlickProjectPage/FlickProjectPage";
import IpnosProject from "../components/IpnosProjectPage/IpnosProjectPage";

import About from "../components/AboutPage/AboutPage";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";

import ScrollToTop from "utils/ScrollToTop";

const AppRouter = () => {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Route path="/" exact component={HomePage} />
      <Route path="/loop-project/" component={LoopProject} />
      <Route path="/flick-project/" component={FlickProject} />
      <Route path="/ipnos-project/" component={IpnosProject} />
      <Route path="/about/" component={About} />
      <Footer />
    </Router>
  );
};

export default AppRouter;
