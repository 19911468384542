import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex, Image, Text, PseudoBox, Box } from "@chakra-ui/core";

import RightArrow from "../../../images/icons/right-arrow.svg";

// -----------------------------------------

const ProjectLink = styled(Link)`
  text-decoration: none;
  color: #dadada;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Project = ({
  projectImage,
  projectTitle,
  projectDesc,
  projectURL,
  projectColor,
}) => {
  return (
    <ProjectLink to={projectURL}>
      <PseudoBox role="group">
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <Box
            display="flex"
            backgroundColor={projectColor}
            flex="0 0 60%"
            justifyContent="center"
            alignItems="center"
            paddingY={{ base: 12, md: 24 }}
            paddingX={{ base: 0, md: 10 }}
            marginY={{ base: 8, md: 0 }}
          >
            <PseudoBox
              transition="all .5s ease"
              _groupHover={{
                transform: "scale(1.05)",
                transition: "all .5s ease",
              }}
            >
              <Image src={projectImage} />
            </PseudoBox>
          </Box>
          <Flex
            flex="0 0 40%"
            flexDirection="column"
            paddingLeft={{ base: 0, md: 10 }}
            justifyContent="center"
          >
            <Text
              fontSize={{ base: "5xl", md: "6xl" }}
              fontWeight="bold"
              marginBottom={10}
              color="gray.200"
            >
              {projectTitle}
            </Text>
            <Text
              fontSize="lg"
              lineHeight="taller"
              marginBottom={8}
              color="gray.00"
            >
              {projectDesc}
            </Text>

            <ProjectLink to={projectURL}>
              <Flex alignItems="flex-end">
                <Text fontSize="md" fontWeight="bold" color="gray.200" pr={3}>
                  View case study
                </Text>
                <PseudoBox
                  transition="all .5s ease"
                  _groupHover={{
                    transform: "translateX(20px);",
                    transition: "all .5s ease",
                  }}
                >
                  <Image height="20px" src={RightArrow} />
                </PseudoBox>
              </Flex>
            </ProjectLink>
          </Flex>
        </Flex>
      </PseudoBox>
    </ProjectLink>
  );
};

export default Project;
