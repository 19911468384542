import React from "react";
import "./App.css";
import AppRouter from "./navigation/AppRouter";
import { theme, ThemeProvider, CSSReset } from "@chakra-ui/core";

import FollowMouse from "utils/followMouse";

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brandOrange: "#FFB951",
    brandBlue: "#4368E5",
    brandDarkGrey: "#1f1f24",
    brandBlack: "#000000",
  },
};

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
