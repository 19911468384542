import React from "react";
import { Box, BoxProps } from "@chakra-ui/core";

const TextContainerWithBackground = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      backgroundColor="brandDarkGrey"
      paddingY={8}
      paddingX={16}
      marginBottom={16}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default TextContainerWithBackground;
