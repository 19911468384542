import React from "react";
import { Box, BoxProps } from "@chakra-ui/core";

const ProjectContentContainer = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      width={{ xl: "964px" }}
      margin="0 auto"
      paddingX={{ base: 8, md: 16 }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ProjectContentContainer;
